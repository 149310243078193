import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {SolarPdfComponent} from './solar-pdf/solar-pdf.component';
import {BatteryPdfComponent} from './battery-pdf/battery-pdf.component';
import { HubbleSolarPdfComponent } from './hubble-solar-pdf/hubble-solar-pdf.component';

const routes: Routes = [
  {
    path: 'solar',
    component: SolarPdfComponent
  },
  {
    path: 'battery',
    component: BatteryPdfComponent
  },
  {
    path: 'hubble-solar',
    component: HubbleSolarPdfComponent
  },
  {
    path: '**',
    component: SolarPdfComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
